import { Button, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addVendor } from '../../../../API/Auth';


function EditVendors() {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        id: '',
        user: '',
        first_name: '',
        last_name: '',
        phone: '',
        // is_approved: false,
        // approved_by: '',
        // approved_date: '',
      });

    const location = useLocation();
    const navigate = useNavigate();

    
    useEffect(() => {
        // const vendor_id = location.state?.vendor_id;
        // if(!vendor_id) {
        //     toast.error("Something went wrong in Fetching Vendor Data");
        //     navigate(-1);
        // }

        // const fetchAVendor = async (value) => {
        //     try {
        //       let response = await AdminAllVendors(`?is_pending=${value}`, GET);
        //       if (response) {
        //         let resProducts = response?.data;
        //         console.log({ resProducts });
        //         setDetails(() => [...resProducts]);
        //       }
        //     } catch (error) {
        //       console.error('Error fetching Vendors Data:', error);
        //       toast.error("Error while Fetching Vendors")
        //     }
        //   };
      
        //   fetchAVendor(vendor_id);

    }, [location])
    

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };

    const handleSubmit = async(e) => {
        e.preventDefault();
        const validationErrors = validateForm(formData);
        const Formdata = new FormData();
        Formdata.append("username", formData.user);
        Formdata.append('first_name', formData.first_name);
        Formdata.append('last_name', formData.last_name);
        Formdata.append('phone', formData.phone);
        let res = await addVendor(Formdata)
          console.log(res)
          if (res?.success){
            toast.success(res?.message)
            navigate('/admin/admin-vendors')
          }

        // if (Object.keys(validationErrors).length === 0) {
        //   let res = await addVendor(Formdata)
        //   console.log(res)
        //   if (res?.success){
        //     toast.success(res?.message)
        //     navigate('/admin-vendors')
        //   }
        // } else {
        //   // Form is invalid, update the errors state
        //   setErrors(validationErrors);
        // }
      };

    const validateForm = (data) => {
        const errors = {};
        Object.keys(data).forEach((key) => {
          if (!data[key]) {
            errors[key] = `${key} is required`;
          }
        });
        return errors;
      };


    return (
        <div>
            <h1 className='text-center mb-4'> Add Vendor </h1>
            <form className='form-wrapper'>

                <TextField
                    label="User Email"
                    name="user"
                    value={formData.user}
                    onChange={handleChange}
                    error={!!errors.user}
                    helperText={errors.user}
                />
                <TextField
                    label="First Name"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleChange}
                    error={!!errors.first_name}
                    helperText={errors.first_name}
                />
                <TextField
                    label="Last Name"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    error={!!errors.last_name}
                    helperText={errors.last_name}
                />
                <TextField
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    error={!!errors.phone}
                    helperText={errors.phone}
                    InputProps={{
                      inputProps: {
                          maxLength: 10, minLength: 10
                      }
                  }}
                    
                />
                {/* <TextField
                    label="Is Approved"
                    name="is_approved"
                    value={formData.is_approved}
                    onChange={handleChange}
                    error={!!errors.is_approved}
                    helperText={errors.is_approved}
                    disabled = {true}
                /> */}
                {/* <TextField
                    label="Approved By"
                    name="approved_by"
                    value={formData.approved_by}
                    onChange={handleChange}
                    error={!!errors.approved_by}
                    helperText={errors.approved_by}
                /> */}
                {/* <TextField
                    label="Approved Date"
                    name="approved_date"
                    value={formData.approved_date}
                    onChange={handleChange}
                    error={!!errors.approved_date}
                    helperText={errors.approved_date}
                /> */}
                <Button type="submit" variant="contained" onClick={handleSubmit} color="primary">
                    Add
                </Button>
            </form>

        </div>

    )
}

export default EditVendors