import React, { useEffect, useState } from 'react';
import { Profile } from '../../../API/Auth';
import { Card, CardContent, Typography } from '@mui/material';
import { toast } from 'react-toastify';

export default function DashboardHome() {
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
  });

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        let profileResponse = await Profile();
        let profile = {};
        if (profileResponse) {
          profile = profileResponse?.data;
          setDetails(prev =>
            ({ ...prev, first_name: profile?.first_name, last_name: profile?.last_name, phone: profile?.phone })
          )
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error("Error while Fetching the data")
      }
    };

    fetchProfile();
  }, []);

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" component="div">
          {`${details.first_name} ${details.last_name}`}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Phone: {details.phone}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Approved Date: {details.approved_date}
        </Typography>
      </CardContent>
    </Card>
  )
}
