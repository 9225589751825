import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faHome,
    faUser,
    faTimes,
    faTags,
    faCartArrowDown,
    faShop
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const SideBar = ({ isOpen, toggle }) => {
    return (
        <div className={`sidebar ${isOpen && "is-open"}`}>
            <div className="sidebar-header">
                <Button
                    variant="link"
                    onClick={toggle}
                    style={{ color: "#fff" }}
                    className="mt-4"
                >
                    <FontAwesomeIcon icon={faTimes} pull="right" size="xs" />
                </Button>
                <h3>
                    <Link to={"/"} className="navLogo">
                        Kaaya
                    </Link>
                </h3>
            </div>

            <Nav className="flex-column pt-2">
                <Nav.Item
                    className={(isActive) =>
                        "nav-link" + (!isActive ? " active" : "active")
                    }
                >
                    <Link className="dash_sidebar_a nav-link" to="/admin">
                        <FontAwesomeIcon icon={faHome} className="mr-2" />
                        Home
                    </Link>
                </Nav.Item>

                <Nav.Item>
                    <Link
                        className="dash_sidebar_a nav-link"
                        to="admin-products"
                    >
                        <FontAwesomeIcon icon={faTags} className="mr-2" />
                        Products
                    </Link>
                </Nav.Item>
                <Nav.Item>
                    <Link
                        className="dash_sidebar_a nav-link"
                        to="admin-vendors"
                    >
                        <FontAwesomeIcon icon={faTags} className="mr-2" />
                        Vendors
                    </Link>
                </Nav.Item>

                {/* <Nav.Item>
                    <Link className="dash_sidebar_a nav-link" to="admin-orders">
                        <FontAwesomeIcon
                            icon={faCartArrowDown}
                            className="mr-2"
                        />
                        Orders
                    </Link>
                </Nav.Item>

                <Nav.Item className="">
                    <Link
                        className="dash_sidebar_a nav-link"
                        to="admin-alluser"
                    >
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        All Users
                    </Link>
                </Nav.Item>

                <Nav.Item className="">
                    <Link className="dash_sidebar_a nav-link" to="admin-vendors">
                        <FontAwesomeIcon icon={faShop} className="mr-2" />
                        Vendors
                    </Link>
                </Nav.Item> */}
            </Nav>
        </div>
    );
};

export default SideBar;
