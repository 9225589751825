import Cookies from 'js-cookie';
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE } from './helpers/StorageKeys';
import axios from 'axios';
import { BACKEND_URL } from './Url';
import { refreshToken, removeCookies } from '../API/Auth';

const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL
  });


const setAuthHeaders = (remove_needed) => {
    const accessToken = Cookies.get(LOGIN_AUTH_COOKIE);
    const refreshToken = Cookies.get(LOGIN_REFRESH_COOKIE);
    const userType = Cookies.get(LOGIN_USERTYPE_COOKIE);
    if (!remove_needed && accessToken) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        instance.defaults.headers.common['User-Type'] = userType;
    }
    else {
        // If there is no accessToken, remove headers
        delete instance.defaults.headers.common['Authorization'];
        delete instance.defaults.headers.common['User-Type'];
    }
};

// Function to make API requests
const makeRequest = async ({ url, method, data, remove_needed }) => {
    setAuthHeaders(remove_needed);

    try {
        const response = await instance({
            url,
            method,
            data,
        });
        return response.data;
    } catch (error) {
        console.error('API request error:', error);

        if (error.response && error.response.status === 401) {
            let refresh = Cookies.get(LOGIN_REFRESH_COOKIE);
            let refreshdict = { refresh: refresh }
            let res = await refreshToken(refreshdict);
            if (res === undefined) {
                Cookies.remove(LOGIN_AUTH_COOKIE);
                Cookies.remove(LOGIN_REFRESH_COOKIE);
            }
            else if (res.access) {
                Cookies.remove(LOGIN_AUTH_COOKIE);
                Cookies.remove(LOGIN_REFRESH_COOKIE);
                Cookies.set(LOGIN_AUTH_COOKIE, res.access);
                Cookies.set(LOGIN_REFRESH_COOKIE, res.refresh);
            }
            // removeCookies()
            // Cookies.set(LOGIN_AUTH_COOKIE, res.access);
            // Cookies.set(LOGIN_REFRESH_COOKIE, res.refresh);
            // window.location.href = '/admin-login';
        }

        throw error;
    }
};

export default makeRequest;