import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ButtonGroup, Button, Typography, Tabs, Tab, Switch } from '@mui/material';
import { AdminAllVendors } from '../../../../API/Auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box } from '@mui/system';
import { GET, POST } from '../../../../utils/helpers/RequestType';

const VendorTable = ({ vendors, approved = true, approveVendor, navigate }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>First Name</TableCell>
          <TableCell>Last Name</TableCell>
          <TableCell>Phone</TableCell>
          {
            approved ? <>
              <TableCell>Approved By</TableCell>
              <TableCell>Approved Date</TableCell>
            </>
              :
              <TableCell>
                Mark as Approved
              </TableCell>
          }
          <TableCell>Operations</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {vendors && vendors?.length > 0 ?
          vendors.map((vendor) => (
            <TableRow key={vendor?.id}>
              <TableCell>{vendor?.first_name}</TableCell>
              <TableCell>{vendor?.last_name}</TableCell>
              <TableCell>{vendor?.phone}</TableCell>
              {
                approved ? <>
                  <TableCell>{vendor?.approved_by}</TableCell>
                  <TableCell>{vendor?.approved_date}</TableCell>
                </>
                  :
                  <TableCell onClick={() => approveVendor && approveVendor(vendor?.id)}>
                    <Switch />
                  </TableCell>
              }
              {/* <TableCell style={{
                cursor: "pointer",
                display: "flex",
                gap: "3px"
              }} >
                <EditIcon onClick={() => navigate(vendor?.id)} />
                <DeleteIcon className='deleteIcon_Vendors' />
              </TableCell> */}
            </TableRow>
          ))
          : (
            <p className='text-center w-100 my-5'>No Vendors found</p>
          )
        }
      </TableBody>
    </Table>
  </TableContainer>
);

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default function AdminVendors() {
  const [details, setDetails] = useState([]);
  const [tabValue, setTabValue] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchVendors = async (value) => {
      try {
        let response = await AdminAllVendors(`?is_pending=${value}`, GET);
        if (response) {
          let resProducts = response?.data;
          setDetails(() => [...resProducts]);
        }
      } catch (error) {
        console.error('Error fetching Vendors Data:', error);
        toast.error("Error while Fetching Vendors")
      }
    };

    fetchVendors(tabValue);

    return () => {
      setDetails([])
    }
  }, [tabValue]);

  async function approveVendor(id) {
    try {
      let response = await AdminAllVendors(`?vendor_id=${id}`, POST);
      if (response) {
        let resProducts = response?.data;
        setDetails((prev) => [...prev.filter(ele => ele.id !== id), ...resProducts].sort((a, b) => a.id - b.id));
      }
    } catch (err) {
      toast.error("Something went wrong in Approving Vendor.")
    }
  }

  function navigateToEditPage(id) {
    navigate("/admin/edit-vendor", {
      state: {
        vendor_id: id
      }
    });
  }


  return (
    <div style={{
      width: "80%",
      margin: "auto"
    }}>
      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <h2>Vendors</h2>
      </div>
      <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="Vendors Tab">
        <Tab label="Approved Vendors" />
        <Tab label="Not Approved" />
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <VendorTable vendors={details} approved={true} navigate={navigateToEditPage} />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <VendorTable vendors={details} approved={false} approveVendor={approveVendor} navigate={navigateToEditPage} />
      </TabPanel>

    </div>
  )
}
