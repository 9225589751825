import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Login from './Components/Login/Login';
import { useLocation } from "react-router-dom";
import Footer from './Components/Footer/Footer';
import useWindowDimensions from './hooks/useWindowDimensions';
import { RouterConfig } from './navigation/RouterConfig';

function App() {
  const loc = useLocation();
  const { width } = useWindowDimensions();
  const matches768 = width >= 768;

  return (
    <>
      <div className="App">
        <RouterConfig />
        {loc.pathname.includes("/admin") ? null : <Footer />}
      </div>

      <ToastContainer position={matches768 ? "bottom-right" : "top-center"} autoClose={5000} hideProgressBar newestOnTop={matches768} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover transition={Bounce} />
    </>
  );
}

export default App;
