import React, { useState, useEffect, useCallback } from "react";
import "./Dashboard.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import SideBar from "./Sidebar";
import Content from "../../../Components/Admin/DashboardContent/Content";
import { getPayloadFromToken } from "../../../utils/helpers";
import { selectToken, selectUser } from "../../../features/userSlice";
import { useSelector } from "react-redux";

function Dashboard() {
    const [isOpen, setIsOpen] = useState(false);
    const [previousWidth, setPrevWidth] = useState(-1);

    const userType = useSelector(selectUser);
    const storedToken = useSelector(selectToken);

    const user = storedToken ? getPayloadFromToken(storedToken) : null;

    const navigate = useNavigate();

    const updateWidth = useCallback(() => {
        const width = window.innerWidth;
        const widthLimit = 576;
        const isMobile = width <= widthLimit;
        const wasMobile = previousWidth <= widthLimit;

        if (isMobile !== wasMobile) {
            setIsOpen(!isMobile);
        }

        setPrevWidth(width);
    }, [previousWidth])


    useEffect(() => {
        // if (userType !== 1 || !user?.user_id) {
        //     navigate("/admin-login")
        // }
        updateWidth();
        window.addEventListener("resize", updateWidth());

        return () => {
            window.addEventListener("resize", updateWidth());
        };
    }, [user, updateWidth, navigate]);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="dashboard_Whole_Wrapper">
            <SideBar toggle={toggle} isOpen={isOpen} />
            <div className="DashboardWrapper">
                <Content toggle={toggle} isOpen={isOpen} />
            </div>
        </div>
    );
}

export default Dashboard;
