import axios from "axios";
// import { getHeader } from "../useHeaders";
import Cookies from "js-cookie";
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE } from "./StorageKeys";
import { BACKEND_URL } from "../Url";


// export const Caller = async(urls, model, requestType, showSuccess = false, multiPart = false, clientId = false) => {
//     var data = false;
//     const headers = await getHeader(multiPart, clientId);
//     var params = {
//       method: requestType,
//       mode: "cors",
//       redentials: "include",
//       withCredentials: true,
//       crossDomain: true,
//       headers: headers,
//     };
  
//     if (model) {
//       params["body"] = multiPart ? model : JSON.stringify(model);
//     }
  
//     try {
//         console.log("hey")
//         const response = await fetch(BACKEND_URL + urls, params);  
//         console.log(response)
//         if(response.statusText == 'Unauthorized'){
            
//         if(clientId){
//           let adminUserType = Cookies.get(LOGIN_USERTYPE_COOKIE);
//           let adminClientId = adminUserType == '1' || adminUserType == 1 ? "" : clientId;
//            Cookies.remove(LOGIN_AUTH_COOKIE+adminClientId);
//            Cookies.remove(LOGIN_REFRESH_COOKIE+adminClientId);
//           Cookies.remove(LOGIN_USERTYPE_COOKIE+adminClientId);
//           window.location.href ="/client/login";
//         }else{
//           Cookies.remove(LOGIN_AUTH_COOKIE);
//           Cookies.remove(LOGIN_REFRESH_COOKIE);
//           Cookies.remove(LOGIN_USERTYPE_COOKIE);
//           window.location.href ="/admin-login";
//         }
        
//       } 
      
//       const responseJson = await response.json();
//     //   const status = await responseHandler(responseJson, showSuccess);    
//     //   // // console.log(model.entries[0])
//     //   data = responseJson;
//     //   if (status) {
//     //       data = responseJson;//responseJson.data;
//     //     }
//     //   else if (!status && responseJson && responseJson.data && responseJson.data.username[0]) {
//     //     alert("Username already exists")
//     //     //return responseJson;
//     // }
    
// }
// catch (error) {
    
//     // console.log('CallerFunction: ' + error.message);
// }

// return data;
// };


export const calculateTimeUntilExpiration = (token) => {
    const tokenData = JSON.parse(window.atob(token.split('.')[1]));
    const expirationTime = tokenData.exp * 1000;
    const currentTime = new Date().getTime();
    const remainingTime = expirationTime - currentTime;

    return remainingTime;
};

export const getPayloadFromToken = token => {
    const encodedPayload = token.split(".")[1];
    return JSON.parse(window.atob(encodedPayload));
}