import React from "react";
import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import NavBar from "../DashboardNavbar/Navbar";

class Content extends React.Component {
    render() {
        return (
            <Container fluid className={`content ${this.props.isOpen && "is-open"}`}>
                <NavBar toggle={this.props.toggle}  heading={this.props.heading}/>
                <Outlet context={{ isOpen: this.props.isOpen }} />
            </Container>
        );
    }
}

export default Content;
