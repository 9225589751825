import React from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "../pages/Admin/Login";
import Dashboard from "../pages/Admin/Dashboard/Dashboard";
import DashboardHome from "../pages/Admin/Dashboard/DashboardHome";
import AdminProducts from "../pages/Admin/Dashboard/Products/AdminProducts";
import AdminVendors from "../pages/Admin/Dashboard/Vendors/AdminVendors";
import EditVendors from "../pages/Admin/Dashboard/Vendors/EditVendors";
import DashboardAddProduct from "../pages/Admin/Dashboard/Products/DashboardAddProduct";

export const RouterConfig = () => {
  return (
    <Routes>
      <Route path="/admin-login" exact element={<Login />} />

      <Route path="/admin" element={<Dashboard />}>
        <Route exact index element={<DashboardHome />} />
        <Route exact path="admin-vendors" element={<AdminVendors />} />
        <Route exact path="edit-vendor" element={<EditVendors />} />
        <Route exact path="add-vendor" element={<EditVendors />} />
        <Route exact path="admin-products" element={<AdminProducts />} />
        <Route
          exact
          path="admin-add-product"
          element={<DashboardAddProduct />}
        />
      </Route>
    </Routes>
  )
}