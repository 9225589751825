import { LOGIN_API, PROFILE, PRODUCTS, VENDORS_DATA, REFRESHTOKEN, ADD_VENDOR } from '../utils/Url';
import { GET, POST } from "../utils/helpers/RequestType";
import makeRequest from "../utils/useHeaders";
import Cookies from "js-cookie";
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE } from "../utils/helpers/StorageKeys";

export function removeCookies() {
  Cookies.remove(LOGIN_AUTH_COOKIE);
  Cookies.remove(LOGIN_REFRESH_COOKIE);
  Cookies.remove(LOGIN_USERTYPE_COOKIE);
}


export const LoginAPI = async (payload) => {

  removeCookies();

  const result = await makeRequest({
    url: LOGIN_API,
    method: POST,
    data: payload,
    remove_needed: true
  });
  return result;
};


export const Profile = async (payload) => {
  const result = await makeRequest({
    url: PROFILE,
    method: GET,
    data: payload,
    remove_needed: false
  });
  return result;
};

export const GetAdminAllProducts = async () => {
  const result = await makeRequest({
    url: PRODUCTS,
    method: GET
  });
  return result;
};

export const refreshToken = async (payload) => {
  const result = await makeRequest({
    url: REFRESHTOKEN,
    method: POST,
    data: payload,
  });
  return result.data;
};
export const addVendor = async (payload) => {
  const result = await makeRequest({
    url: ADD_VENDOR,
    method: POST,
    data: payload,
  });
  return result;
};

export const AdminAllVendors = async (query, method) => {
  const result = await makeRequest({
    url: `${VENDORS_DATA + query}`,
    method
  });
  return result;
};