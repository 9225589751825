import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, ButtonGroup, Button } from '@mui/material';
import { GetAdminAllProducts } from '../../../../API/Auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ProductTable = ({ products }) => (
  <TableContainer component={Paper}>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>EAN</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Approved By</TableCell>
          <TableCell>Operations</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {products && products?.length > 0 ?
          products.map((product) => (
            <TableRow key={product?.id}>
              <TableCell>{product?.title}</TableCell>
              <TableCell>{product?.ean}</TableCell>
              <TableCell>{product?.mop}</TableCell>
              <TableCell>{product?.approved_by ? product.approved_by?.username : 'Not Approved'}</TableCell>
              <TableCell>
                <EditIcon />
                <DeleteIcon/>
              </TableCell>
            </TableRow>
          ))
          : (
            <p className='text-center w-100 my-5'>No products found</p>
          )
        }
      </TableBody>
    </Table>
  </TableContainer>
);

export default function AdminProducts() {

  const [details, setDetails] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        let response = await GetAdminAllProducts();
        if (response.data) {
          let resProducts = response?.data;
          setDetails(() => [...resProducts]);
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast.error("Error while Fetching the data")
      }
    };

    fetchProducts();
  }, []);

  return (
    <div style={{
      width: "80%",
      margin: "auto"
    }}>
      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <h2>Products</h2>
        <ButtonGroup className=''>
          <Button variant='outlined' size='small' onClick={() => navigate("/admin/admin-add-product")}>Add Product</Button>
        </ButtonGroup>
      </div>
      <ProductTable products={details} />
    </div>
  )
}
