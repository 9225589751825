import { createSlice } from '@reduxjs/toolkit';

const TOKEN_NAME = process.env.REACT_APP_TOKEN_KEY;
const USER_TYPE = process.env.REACT_APP_USER_TYPE;

const EXISTING_USER_TYPE = localStorage.getItem(USER_TYPE) ?? null;
const EXISTING_USER_TOKEN = localStorage.getItem(TOKEN_NAME) ?? null;

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userType: EXISTING_USER_TYPE,
    token: EXISTING_USER_TOKEN
  },
  reducers: {
    loginReducer: (state, action) => {
      const { userType, token } = action.payload;
      state.userType = userType;
      state.token = token;

      localStorage.setItem(TOKEN_NAME, token)
      localStorage.setItem(USER_TYPE, userType)
    },
    logoutReducer: (state) => {
      state.userType = null;
      state.token = null;

      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(USER_TYPE);
    },
  },
});

export const { loginReducer, logoutReducer } = userSlice.actions;

export const selectUser = (state) => state.user.userType ? Number(state.user.userType) : null;
export const selectToken = (state) => state.user.token;

export default userSlice.reducer;