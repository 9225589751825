import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginBG from "../../assets/Login/Login_BG.png"
import HeaderBar from "../../Components/Admin/HeaderBar/HeaderBar";
import { calculateTimeUntilExpiration } from "../../utils/helpers";
import CircleLoading from "../CircleLoading";
import { LoginAPI } from "../../API/Auth";
import { loginReducer } from "../../features/userSlice";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { LOGIN_AUTH_COOKIE, LOGIN_REFRESH_COOKIE, LOGIN_USERTYPE_COOKIE } from "../../utils/helpers/StorageKeys";


const AdminLogin = () => {
    const [btnDisable, setBtnDisable] = useState(true);
    const [loading, setLoading] = useState(false);
    const [email, SetEmail] = useState("");
    const [password, setPassword] = useState("");
    const dispatch = useDispatch();

    const nav = useNavigate();
    const location = useLocation();

    // useEffect(() => {
    //     if (location.search) {
    //         handleLogoutRedirection(location);
    //     }
    // }, []);

    // const handleLogoutRedirection = (location) => {
    //     const searchQuery = new URLSearchParams(location.search.slice(1));
    //     if (searchQuery.has('action') && searchQuery.get('action') === 'logout') {
    //         logoutAdmin();
    //     }
    // }

    // function logoutAdmin() {
    //     AdminLogout("admin", nav);
    //     toast.error("Admin Token expired! Logging out! Please login again")
    // }

    const formSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("username", email);
        formData.append('password', password);

        let res = await LoginAPI(formData)
            setLoading(true);
            setBtnDisable(true);
            Cookies.remove(LOGIN_AUTH_COOKIE);
            Cookies.remove(LOGIN_REFRESH_COOKIE);
            Cookies.remove(LOGIN_USERTYPE_COOKIE);

            if (res?.success) {
                toast.success(res?.message)
                // Cookies.remove(LOGIN_AUTH_COOKIE);
                // Cookies.remove(LOGIN_REFRESH_COOKIE);
                // Cookies.remove(LOGIN_USERTYPE_COOKIE);

                Cookies.set(LOGIN_AUTH_COOKIE, res.data.access);
                Cookies.set(LOGIN_REFRESH_COOKIE, res.data.refresh);
                Cookies.set(LOGIN_USERTYPE_COOKIE, res.data.userType);
                nav("/admin");
            } else toast.error(res?.message ?? 'Login failed')
    };

    return (
        <>
            <HeaderBar alternateWay='home' alternateLink={"/"} />
            <div className="signUpParentClass" style={{
                background: `url(${LoginBG}) no-repeat`,
                backgroundSize: "cover",
            }}>
                <div className="signup-wrapper" style={{
                    backdropFilter: "blur(0)",
                    background: "none",
                    padding: "25px",
                    paddingBottom: "115px"
                }}>
                    <div className="signup-header">
                        <h1 className="page-heading">Admin Login</h1>
                    </div>
                    <form action="" className={"signup-form"} onSubmit={formSubmit} style={{ gap: "20px" }}>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Email</div>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    required
                                    className="input-field admin_required_field"
                                    value={email}
                                    onChange={(e) => {
                                        SetEmail(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="inputfield-Container" style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "6px"
                        }}>
                            <div className="SignUpInputLable">Password</div>
                            <div>
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    required
                                    className="input-field admin_required_field"
                                    value={password}
                                    onChange={(e) => {
                                        setPassword(e.target.value);
                                    }}
                                />
                            </div>
                        </div>


                        <div className={"button-Container"}>
                            <button type="submit" className="submit-button" disabled={email && password ? false : true}>
                                {loading ? (
                                    <div style={{ height: "15px", textAlign: "center", position: "absolute" }}>
                                        <CircleLoading />
                                    </div>
                                ) : (
                                    <p>continue</p>
                                )}
                            </button>
                        </div>


                    </form>
                </div>
            </div>
        </>
    );

};

export default AdminLogin;

